<template>
  <div class="manager-works-shelf-page">
    <div class="header-bar">
      <div class="search-wrapper">
        <el-form
          :inline="true"
          :model="searchForm"
          class="search-form"
          size="small"
          ref="searchForm"
        >
          <el-form-item label="">
            <el-input
              v-model="searchForm.search"
              placeholder="作品名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
              size="mini"
              v-model="searchForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleSearch"
              size="mini"
              class="green-btn"
              >查询</el-button
            >
            <el-button
              @click="handleRefresh"
              size="mini"
              class="green-plain-btn"
              >刷新</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="opt-container">
        <div
          class="opt-btn"
          @click.stop="() => {
            showCheckbox = true;
            isSelectedAll = false;
          }"
          v-if="!showCheckbox"
        >
          <i class="iconfont icon-xiajia"></i>
          <span>取消下架</span>
        </div>
        <div class="xiajia-btn" v-else>
          <div class="select-all" @click.stop v-show="showCheckbox">
            <el-checkbox v-model="isSelectedAll" @change="handleSelectAll"
              >全选</el-checkbox
            >
          </div>
          <i
            class="iconfont icon-xiajia"
            title="取消下架"
            @click.stop="handleBatchXiaJia"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="works-content"
      v-loading="pageLoading"
      element-loading-background="rgba(0, 0, 0, 0.3)"
      @click="handleCancelCheck"
    >
      <div
        class="waterfall-wrapper"
        ref="dwaterfallContainer"
        v-show="!isEmpty"
      >
        <HWaterfall
          ref="dwaterfallWrapper"
          :col="maxCols"
          :width="210"
          :gutterWidth="15"
          :data="waterfallList"
          height="calc(100vh - 120px)"
          @loadmore="getData"
          :lazyDistance="0"
          :loadDistance="100"
          @finish="handleFinish"
        >
          <template>
            <div
              class="cell-item"
              :class="{
                'hover-item': !showCheckbox,
                active: '' == item.id,
                check: showCheckbox,
                checked: item.checked,
              }"
              v-for="(item, index) in waterfallList"
              @click.stop="handleViewImage(item)"
              :key="index"
            >
              <!-- <el-image
                class="img"
                :src="item.obsThumbUrl || item.obsUrl"
                fit="contain"
              >
                <div slot="error" class="img-error">
                  <i
                    class="iconfont icon-zanwutupian"
                    style="font-size: 100px"
                  ></i>
                </div>
              </el-image> -->
              <img
                v-if="item.obsThumbUrl"
                :src="item.obsThumbUrl"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width"
                :data-height="item.height"
              />
              <img
                v-else-if="item.type==1"
                src="@/assets/images/coverdefault.png"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width-4"
              />
              <img
                v-else-if="item.type==0"
                src="@/assets/images/image_cover_default.png"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width-4"
              />
              <div class="img-error" v-else>
                <i
                  class="iconfont icon-zanwutupian"
                  style="font-size: 100px"
                ></i>
              </div>
              <div class="operation-container">
                <div class="opt-btns opt-btns-name">
                  <!-- <div class="opt-name"> -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.offComment"
                    placement="top-start"
                  >
                    <span class="opt-reason">{{ item.offComment }}</span>
                  </el-tooltip>
                  <!-- </div> -->
                </div>
                <span class="more-icon">
                  <span class="icon-wrapper" title="暂无更多">
                    <i class="iconfont icon-gengduo1"></i>
                  </span>
                </span>
              </div>
              <div class="xiajia-container">
                <div class="btn-wrapper" @click.stop="handleXiaJia(item)">
                  <span class="txt">取消下架</span>
                </div>
              </div>
              <div class="name-wrapper">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="opt-name">{{ item.name }}</span>
                </el-tooltip>
              </div>
              <div class="check-wrapper" @click.stop v-show="showCheckbox">
                <el-checkbox
                  v-model="item.checked"
                  @change="(checked) => handleCheckedChange(checked, item)"
                ></el-checkbox>
              </div>
              <!-- 模型标记 -->
              <div class="model-flag" v-if="item.type == 1">
                <img src="../../../assets/images/icon/icon_models.png" alt="" />
              </div>
            </div>
          </template>
        </HWaterfall>
      </div>

      <Empty v-if="isEmpty" description="没有找到相关作品" />
    </div>
    <el-dialog
      :visible.sync="dialogShow"
      :close-on-click-modal="false"
      custom-class="work-info-dialog"
      :before-close="handleBeforeClose"
    >
      <div class="img-wrapper">
        <img :src="imgInfo.obsUrl || imgInfo.obsThumbUrl" alt="" />
      </div>
      <div class="info-wrapper">
        <div class="name-wrapper">
          <span class="label">名称：</span>
          <span class="value">{{ imgInfo.name }}</span>
        </div>
        <div class="name-wrapper">
          <span class="label">下架原因：</span>
          <span class="value">{{ imgInfo.offComment }}</span>
        </div>

        <div class="btn-xiajia" @click.stop="handleXiaJia(imgInfo)">
          取消下架
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogFormShow"
      :close-on-click-modal="false"
      custom-class="form-dialog"
      :before-close="handleFormClose"
      title="取消下架原因"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="xForm" class="x-form">
        <el-form-item prop="comment">
          <el-input
            show-word-limit
            :maxlength="255"
            type="textarea"
            v-model="form.comment"
            placeholder="请填写详细原因"
            :autosize="{ minRows: 2, maxRows: 10 }"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-group">
          <el-button plain class="s-btn" @click="onSubmit">提交</el-button>
          <el-button class="c-btn" @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      SUCCESS_CODE: 0,
      waterfallList: [],
      pageParams: {
        pageSize: 50,
        pageNum: 0,
        total: 0,
        currentPage: 0,
      },
      pageLoading: false,
      isEmpty: false,
      imgDataInfo: {},
      maxCols: 5,
      folderInfo: {}, // 文件夹详情
      id: "", // 图片集id
      dialogShow: false,
      imgInfo: {},
      showCheckbox: false,
      checkedList: [],
      dialogFormShow: false,
      searchForm: {
        search: "",
        time: [],
      },
      form: {
        // type: 1,
        comment: "",
      },
      rules: {
        // type: [{ required: true, message: "请选择类型", trigger: "change" }],
        comment: [
          { required: true, message: "请填写详细原因", trigger: "blur" },
        ],
      },
      isSelectedAll: false,
    };
  },
  created() {
    // this.id = this.$route.params.id;
    // this.getFolderInfo(this.id);
    this.getData(true);
  },
  mounted() {
    this.caculateCol();
    let self = this;
    window.onresize = function () {
      self.caculateCol();
    };
  },
  methods: {
    caculateCol() {
      let width = this.$refs.dwaterfallContainer.clientWidth;
      this.maxCols = parseInt(width / 225);
      console.log(this.maxCols);
    },
    // 瀑布流元素渲染完成
    handleFinish() {
      console.log("----渲染完成7----");
      // this.isLoad = false;
      this.pageLoading = false;
      // this.loadingInstance.close();
    },
    // 获取图片集资源
    getData(flag) {
      // console.log("loadmore");
      if (flag) {
        this.waterfallList = [];
        this.pageParams = {
          pageSize: 50,
          pageNum: 0,
          total: 0,
          currentPage: 0,
        };
      }
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        // this.$refs.waterfall.waterfallOver();
        return;
      }
      this.pageLoading = true;
      this.pageParams.pageNum++;
      let params = Object.assign({}, this.pageParams);
      params.search = this.searchForm.search;
      if (this.searchForm.time && this.searchForm.time.length) {
        params.start = this.searchForm.time[0];
        params.end = this.searchForm.time[1];
      }
      this.$http
        .get("/resource-info/offShelfList", { params })
        .then((res) => {
          // console.log(res);
          if (res.code == 0) {
            let data = res.data;
            data.records.forEach((item) => {
              item.checked = false;
            });
            this.pageParams.total = data.total;
            this.totalImage = data.total;
            this.pageParams.currentPage = data.current;
            if (data.records.length) {
              this.waterfallList = this.waterfallList.concat(data.records);
            }
            this.isEmpty = this.waterfallList.length ? false : true;
            if (this.isEmpty) {
              this.pageLoading = false;
            }
          } else {
            this.pageLoading = false;
          }
        })
        .catch((err) => {
          this.waterfallList = [];
          this.isEmpty = true;
          this.pageLoading = false;
        });
    },
    // 查询图集详情
    getFolderInfo(id) {
      this.$http.get("/directory-info/comDireInfo/" + id).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          this.folderInfo = res.data;
        }
      });
    },
    handleViewImage(data) {
      if (this.showCheckbox) return;
      this.dialogShow = true;
      this.imgInfo = data;
    },
    handleBeforeClose(done) {
      done();
    },
    // 图片复选框,实现图片的勾选和取消勾选
    handleCheckedChange(checked, data) {
      if (checked) {
        this.checkedList.push(data);
      } else {
        this.checkedList.forEach((item, index) => {
          if (item.id == data.id) {
            this.checkedList.splice(index, 1);
          }
        });
      }
    },
    // 单个取消下架
    handleXiaJia(item) {
      this.dialogFormShow = true;
      this.checkedList = [item];
    },
    // 批量取消下架
    handleBatchXiaJia() {
      if (this.checkedList.length) {
        this.dialogFormShow = true;
      } else {
        this.$message.warning("请选择要取消下架的作品！");
      }
    },
    cancelOffShelf() {
      let rids = this.checkedList.map((item) => item.id).join();
      let params = {
        rids,
        // type: this.form.type,
        comment: this.form.comment,
        status: 0,
      };
      // console.log(params);
      this.$http
        .post("/resource-info/offShelfBatch", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("取消下架成功！");
            this.showCheckbox = false;
            this.$refs.xForm.resetFields();
            this.dialogFormShow = false;
            this.dialogShow = false;
            this.checkedList = [];
            this.getData(true);
          } else {
            this.$message.error("操作失败！");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败！");
        });
    },
    handleFormClose(done) {
      this.$refs.xForm.resetFields();
      done();
    },
    onSubmit() {
      this.$refs.xForm.validate((valid) => {
        if (valid) {
          this.$confirm("此操作将取消下架该作品, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => {
              this.cancelOffShelf();
            })
            .catch(() => {});
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.showCheckbox = false;
      this.checkedList = [];
      this.imgInfo = {};
      this.$refs.xForm.resetFields();
      this.dialogFormShow = false;
      this.dialogShow = false;
      this.waterfallList.forEach((item) => {
        item.checked = false;
      });
    },
    handleCancelCheck() {
      this.showCheckbox = false;
      this.checkedList = [];
      this.waterfallList.forEach((item) => {
        item.checked = false;
      });
    },
    // 搜索
    handleSearch() {
      // console.log(this.searchForm);
      this.getData(true);
    },
    // 刷新
    handleRefresh() {
      this.$refs.searchForm.resetFields();
      this.searchForm = {
        search: "",
        time: [],
      };
      this.getData(true);
    },
    // 全选
    handleSelectAll(checked) {
      if (checked) {
        // 全选
        this.waterfallList.forEach((item) => {
          item.checked = true;
        });
        this.checkedList = [...this.waterfallList];
      } else {
        // 全不选
        this.waterfallList.forEach((item) => {
          item.checked = false;
        });
        this.checkedList = [];
      }
      // console.log(this.checkedList)
    },
  },
};
</script>
